<script setup lang='ts'>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'

const { layoutConfig, onMenuToggle } = useLayout()
const outsideClickListener = ref(null)
const topbarMenuActive = ref(false)
const router = useRouter()

// const storeUser = useCompanyStore()
/**
 * #USER CHECK LOGIN
 */
/* if (process.client) {
	const storeUser = useCompanyStore()
	await storeUser.loadUser()
	if (!storeUser.getUser) {
		await router.push('/login')
	} else {
		await router.push('/')
	}
} */
/* const session = await useAuth().getSession()
console.log('pre session: ', session) */

onMounted(async () => {
	// await userCompose().loadUser()
	/* console.log('appTopBar userCompose().state: ', userCompose().state) */
	// await useCompanyStore().loadUser()
	// console.log('useCompanyStore().getUser: ', useCompanyStore().getUser)

	// DEFINITIVO COME CARICARE L'UTENTE AL LOGIN - spostato in default.vue
	// await useUserSessionManagement().loadUserFromSession()
	// console.log('useUserLoadTest().user: ', useUserSessionManagement().getUser())
	/* if (process.client) {
		// await nextTick()
		// await useCompanyStore().loadUser()
		const session = await useAuth().getSession()
		console.log('pre session: ', session)
		if (session) {
			console.log('if session: ', session)
			// storeUser.setUser(session.user as any)
			const sessionCopy = { ...session }
			// useUserLoadTest().setUser(sessionCopy.user as any)
		}
	} */
	bindOutsideClickListener()
	window.addEventListener('scroll', handleScroll)
})
onBeforeUnmount(() => {
	unbindOutsideClickListener()
	window.removeEventListener('scroll', handleScroll)
})
const logoUrl = computed(() => {
	return appConfig.infoapp.imgLogo
	// return `/${layoutConfig.darkTheme.value ? 'logo1_tra1' : 'logo1_tra'}.png`
})

const onTopBarMenuButton = () => {
	topbarMenuActive.value = !topbarMenuActive.value
}

const onSettingsClick = () => {
	topbarMenuActive.value = false
	router.push('/utilities/documentation')
}

const topbarMenuClasses = computed(() => {
	return {
		'layout-topbar-menu-mobile-active': topbarMenuActive.value
	}
})

const bindOutsideClickListener = () => {
	if (!outsideClickListener.value) {
		outsideClickListener.value = (event) => {
			if (isOutsideClicked(event)) {
				topbarMenuActive.value = false
			}
		}

		document.addEventListener('click', outsideClickListener.value)
	}
}

const unbindOutsideClickListener = () => {
	if (outsideClickListener.value) {
		document.removeEventListener('click', outsideClickListener)
		outsideClickListener.value = null
	}
}

const isOutsideClicked = (event) => {
	if (!topbarMenuActive.value) { return }
	const sidebarEl = document.querySelector('.layout-topbar-menu')
	const topbarEl = document.querySelector('.layout-topbar-menu-button')

	return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target))
}

const emit = defineEmits(['menuToggle'])

const themeStore = useThemeStore()
const op = ref<any>(null)
const opPreferiti = ref<any>(null)
const opUser = ref<any>(null)

function toggle(event: any) {
	op.value.toggle(event)
}
const togglePreferiti = (event: any) => {
	opPreferiti.value.toggle(event)
}
const userMenuActive = ref(false)
function toggleUser(event: any) {
	// opUser.value.toggle(event)
	userMenuActive.value = !userMenuActive.value

	// userCompose().openMenu.value = !userCompose().openMenu.value
}

function redirectToGithub(event: any) {
	window.open('https://github.com/sfxcode/nuxt3-primevue-starter', '_blank')
}

const isTopbarVisible = ref(true)
const handleScroll = () => {
	const scrollPosition = window.scrollY
	if (scrollPosition > 50) {
		isTopbarVisible.value = false
	} else {
		isTopbarVisible.value = true
	}
}

/* const computeColorLogo = computed(() => {
	return appConfig.infoapp.getDarkLightLogo()
}) */
const switchingModeRef = ref(false)

const switchingMode = () => {
	if (themeStore.themeName === 'saga') {
		themeStore.setDim()
		// switchingModeRef.value = true
	} else {
		themeStore.setLight()
		// switchingModeRef.value = false
	}
}
onMounted(() => {
	// switchingModeRef.value = themeStore.isDarkMode
	/* switchingMode()
	console.log('switchingModeRef.value: ', switchingModeRef.value) */
})
const computedSwitchingMode = computed(() => {
	return switchingModeRef.value
})
watch(() => themeStore.isDarkMode, (newVal) => {
	switchingModeRef.value = !newVal
})
// switchingModeRef.value = computedSwitchingMode.value
</script>

<template>
  <div v-show="isTopbarVisible" class="layout-topbar">
    <NuxtLink to="/" class="layout-topbar-logo">
      <img :src="themeStore.getLogo" :alt="appConfig.infoapp.Title" class="ml-2">
      <div class="mb-1 ml-3 text-nowarp text-xl font-bold ">
        {{ appConfig.infoapp.Title }}
        <!--<br> <span class="text-xs text-gray-500">ver. {{ appConfig.infoapp.version }}
        </span> -->
      </div>
    </NuxtLink>

    <button class="p-link layout-menu-button layout-topbar-button pl-0" @click="onMenuToggle()">
      <i class="pi pi-bars" />
    </button>

    <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()">
      <i class="pi pi-ellipsis-v" />
    </button>

    <div class="layout-topbar-menu" :class="topbarMenuClasses">
      <button v-posthog-capture="'cta_preferiti'" class="p-link layout-topbar-button" @click="togglePreferiti">
        <!-- <i class="pi pi-star text-yellow" /> -->
        <Icon name="line-md:star-filled" size="25" class="text-yellow" />
        <span>Preferiti</span>
      </button>

      <button v-posthog-capture="'cta_setting'" class="p-link layout-topbar-button" @click="toggle">
        <!--  <i class="pi pi-cog text-blue-500" /> -->
        <Icon name="line-md:cog" size="25" class="text-blue-500" />
        <span>Impostazioni</span>
      </button>
      <button v-posthog-capture="'cta_profilo'" class="p-link layout-topbar-button" @click="toggleUser">
        <!-- <i class="pi pi-user text-green-400" />1 -->
        <Icon name="line-md:account" size="25" class="text-green-400" />
        <span>Profilo</span>
      </button>
      <!--   <button class="p-link layout-topbar-button" @click="redirectToGithub">
        <i class="pi pi-github" />
        <span>Github</span>
      </button> -->
    </div>
    <!-- user Shortcuts in overlayPanel with list buttons -->
    <ClientOnly>
      <!-- <OverlayPanel id="overlay_panel_user" ref="opUser" append-to="body" style="width: 500px">
        <UserAuthBox />
      </OverlayPanel> -->
      <Sidebar v-model:visible="userMenuActive" header="Right Sidebar" position="right">
        <UserAuthBox />
      </Sidebar>
    </ClientOnly>

    <OverlayPanel id="panel_preferiti" ref="opPreferiti" append-to="body" class="w-30rem" @click="()=>{opPreferiti.hide()}">
      <UserPreferiti />
    </OverlayPanel>

    <client-only>
      <OverlayPanel id="overlay_panel" ref="op" append-to="body" style="width: 200px">
        <!-- <h6>Layout Mode</h6> -->
        <h6>Modalità</h6>
        <div id="switchingMode" class="flex align-content-center justify-content-center align-items-center">
          <div class="mr-4">
            <!--  <i class="pi pi-moon text-2xl" :class="themeStore.isDarkMode ? 'text-primary' : 'text-gray-500'" /> -->
            <Icon name="line-md:moon-filled" size="30" :class="themeStore.isDarkMode ? 'text-primary' : 'text-gray-500'" />
          </div>
          <div class="">
            <!-- <InputSwitch v-model="switchingModeRef" @change="switchingMode" /> -->
            <InputSwitch v-model="switchingModeRef" @click="switchingMode" />
          </div>
          <div class="ml-4">
            <!-- <i class="pi pi-sun text-2xl" :class="!themeStore.isDarkMode ? 'text-primary' : 'text-gray-500'" /> -->
            <Icon name="line-md:moon-alt-to-sunny-outline-loop-transition" size="30" :class="!themeStore.isDarkMode ? 'text-primary' : 'text-gray-500'" />
          </div>
        </div>
        <!-- <div class="field-radiobutton">
          <RadioButton
            id="saga"
            v-model="themeStore.themeName"
            name="layoutColorMode"
            value="saga"
            @change="themeStore.setLight()"
          />
          <label>Chiaro</label>
        </div>
        <div class="field-radiobutton">
          <RadioButton
            id="vela"
            v-model="themeStore.themeName"
            name="layoutColorMode"
            value="vela"
            @change="themeStore.setDim()"
          />
          <label>Scuro</label>
        </div> -->
        <!-- <div class="field-radiobutton">
          <RadioButton
            id="arya"
            v-model="themeStore.themeName"
            name="layoutColorMode"
            value="arya"
            @change="themeStore.setDark()"
          />
          <label>Dark</label>
        </div> -->

        <h6>Colori</h6>
        <div class="flex">
          <div
            style="width:2rem;height:2rem;border-radius:6px"
            class="bg-blue-500 mr-3 cursor-pointer"
            @click="themeStore.setColor('blue')"
          />
          <div
            style="width:2rem;height:2rem;border-radius:6px"
            class="bg-green-500 mr-3 cursor-pointer"
            @click="themeStore.setColor('green')"
          />
          <div
            style="width:2rem;height:2rem;border-radius:6px"
            class="bg-yellow-300 mr-3 cursor-pointer"
            @click="themeStore.setColor('orange')"
          />
          <div
            style="width:2rem;height:2rem;border-radius:6px"
            class="bg-purple-500 cursor-pointer"
            @click="themeStore.setColor('purple')"
          />
        </div>
      </OverlayPanel>
    </client-only>
  </div>
  <div class="mt-0">
    <!--src="/images/home_icone/A_sfondo_top_left.png"-->
    <!--  <img
      :src="themeStore.isDarkMode ? '/images/home_icone/home_deleganoi_transparent_dark.png' : '/images/home_icone/home_deleganoi_transparent_white.png'"
      alt="Image"
      class="absolute top-20 left-0 h-16rem"
      style="z-index: 1;"
    > -->
  </div>
</template>

<style lang="scss" scoped></style>
